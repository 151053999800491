import React from "react"
import CEOImage from "../../../resources/AboutUs/swatiDi.jpeg"

import "./RightText.css"

export default function RightText(props) {
  return (
    <div className="rightTextContainer">
      <img
        src={CEOImage}
        className="rounded-circle ceo-img"
        alt="Avatar"
        style={{
          marginBottom: "32px",
        }}
      />
      <div className="rightText">
        <span>Swati Sinha</span>
        <p>
          Swati Sinha, the dynamic founder of Savanna HR, a leading human
          resources consulting firm. With a career spanning over two decades,
          Swati has been at the forefront of transforming HR practices across
          various industries. Her deep expertise in talent acquisition, employee
          engagement, and organizational development has helped countless
          businesses achieve their full potential. Swati's innovative approach
          and unwavering commitment to excellence have established her as a
          thought leader in the HR community. In this blog, she shares her
          insights and experiences, offering valuable guidance to HR
          professionals and business leaders alike.
        </p>
        <span style={{ fontSize: "21px" }}>How can we help you?</span>
        <p>
          Whether you want one person or an entire project team, and whether
          your needs are short-term or full-time, we match you to skilled
          professionals in accounting and finance, technology, creative and
          marketing, legal and administrative fields. Results matter and so does
          your time, so let us help you build the productive, engaged workforce
          that will keep your company moving forward.
        </p>
        <span style={{ fontSize: "21px" }}>Our story</span>
        <p>
          Whether you want one person or an entire project team, and whether
          your needs are short-term or full-time, we match you to skilled
          professionals in accounting and finance, technology, creative and
          marketing, legal and administrative fields. Results matter and so does
          your time, so let us help you build the productive, engaged workforce
          that will keep your company moving forward.
        </p>
      </div>
    </div>
  )
}
